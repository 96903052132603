import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const EXHIBITOR_CONTACT_FULL_FRAGMENT = `
  fragment exhibitorContactFullFragment on ExhibitorContact {
    uid
    schemaCode
    type
    name
    phone
    fax
    email
    isPublic
    exhibitor {
      ...exhibitorBaseFragment
    }
  }
  ${EXHIBITOR_BASE_FRAGMENT}
`;
